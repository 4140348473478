@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.input {
	border: 1px solid $color-dark;
	width: 100%;
	@include font-size(16);
	padding: 12px 10px;
	line-height: 1;
	text-align: left;
	margin-bottom: 20px;
	font-family: $base-font-family;
	transition: all 0.4s;
	background-color: $color-lightest;
	color: $color-darkest;

	&:focus {
		outline: 0;
		border-color: $color-tertiary;
		box-shadow: 0px 0px 3px 1px $color-tertiary;
	}

	&.inputError {
		border-color: $color-error;
		color: $color-error;
		margin-bottom: 5px;
	}

	&.full {
		width: 100%;
	}

	&.relative {
		width: auto;
	}
}

.label {
	@include font-size(16);
	color: $color-darkest;
	margin-bottom: 5px;
	font-weight: normal;
	text-align: left;
	width: 100%;
	display: block;
	color: $color-dark;
}

.error {
	color: $color-error;
	text-align: left;
	margin-bottom: 10px;
}

.search {
	position: relative;

	.input {
		padding-left: 40px;
	}
	svg {
		width: 24px;
		height: 24px;
		cursor: pointer;
		position: absolute;
		top: 12px;
		left: 10px;
		z-index: 2;
		path {
			fill: $color-darkest;
		}
	}
}
