@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;
@use "sass:color";

.notice {
	padding: 0px 10px;
	line-height: 34px;
	display: inline-block;
	@include font-size(16);
	background-color: color.scale($color-darkest, $lightness: 50%);

	&.small {
		@include font-size(14);
		padding: 5px;
	}

	&.full {
		width: 100%;
	}

	&.error {
		color: $color-lightest;
		background-color: $color-error;
	}
}
